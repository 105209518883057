import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as am5 from "@amcharts/amcharts5/"
import * as am5percent from "@amcharts/amcharts5/percent"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as styles from "../../../styles/components/chart.module.scss"
import nl2br from "react-nl2br"
import canvasMemoryReset from "../../../utils/canvas-memory-reset"
import specialCharacterReplace from "../../../utils/special-character-replace"

// markup
class PieSelected extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData,
      dataSourceUrl: props.chartData,
      type: 'number'
    }
    
    this.series = [];
    this.filePath = "/csv/pie-selected/" // CSVデータ格納先
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  // グラフ切り替えメニュー
  handleChange(e) {
    let name = e.target.name
    this.setState({[name]: e.target.value})
    this.handleBlur(e);
  }

  // データソースの切り替え
  handleBlur(e) {
    this.setState({dataSourceUrl: e.target.value})
  }
  
  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    am5.addLicense(process.env.AM_CHARTS_LICENSE)

    const chartConfig = this.getConfig()

    let root = am5.Root.new(this.props.id)
    root.setThemes([
      am5themes_Animated.new(root)
    ])
    this.root = root

    // 基本設定
    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(40)
    }));
    
    // CSVファイルからデータの読み込み
    let dataSource = `${this.filePath}${this.state.chartData}-${this.state.type}.csv`
    let data = await am5.net.load(dataSource).then(function(result) {
      // CSVパースオプション
      let data = am5.CSVParser.parse(result.response, {
        delimiter: ",",
        reverse: false,
        skipEmpty: true,
        useColumnNames: true
      });
      // 型変換処理
      let processor = am5.DataProcessor.new(root, {
        numericFields: ['value']
      });
      processor.processMany(data);

      return data
    })

    // ラベル
    chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: "center",
        centerY: am5.p50,
        centerX: am5.p50,
        text: chartConfig.title[this.state.chartData][this.state.type]
      })
    );

    // シリーズデータ作成
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        endAngle: 270,
        alignLabels: false
      })
    );

    // テンプレート指定
    series.labels.template.setAll({
      fontSize: "11px",
      inside: true,
      text: chartConfig.tooltip[this.state.chartData][this.state.type]
    })

    // 色指定
    series.get("colors").set("colors", chartConfig.series[this.state.chartData].map(item => {
      return item.fill
    }))
    
    series.states.create("hidden", {
      endAngle: -90
    });

    series.slices.template.set("tooltipText", "")

    series.data.setAll(data)

    this.chart = chart
  }

  componentDidMount() {
    this.setChartData()
  }

  componentDidUpdate(prevState) {
    if (prevState.dataSourceUrl !== this.state.dataSourceUrl ) {
      canvasMemoryReset(`#${this.props.id} canvas`)
      this.root.dispose()
      this.setChartData()
    }
  }

  componentWillUnmount() {
    canvasMemoryReset(`#${this.props.id} canvas`)
    if (this.root) {
      this.root.dispose()
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query allPieSelectedJsonQuery {
            settings: allPieSelectedJson {
              edges {
                node {
                  select {
                    co2EUbudget {
                      label
                      name
                    }
                    co2USAbudget {
                      label
                      name
                    }
                  }
                  series {
                    co2EUbudget {
                      fill
                      label
                      name
                    }
                    co2USAbudget {
                      fill
                      label
                      name
                    }
                  }
                  tooltip {
                    co2EUbudget {
                      number
                      budget
                    }
                    co2USAbudget {
                      number
                      budget
                    }
                  }
                  head {
                    co2EUbudget
                    co2USAbudget
                  }
                  title {
                    co2EUbudget {
                      number
                      budget
                    }
                    co2USAbudget {
                      number
                      budget
                    }
                  }
                  source {
                    co2EUbudget {
                      explain
                      sourceLink1
                      sourceText1
                      between
                      sourceLink2
                      sourceText2
                      after
                    }
                    co2USAbudget {
                      explain
                      sourceLink1
                      sourceText1
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            <div className={styles.chartTitle}>
              <h2 dangerouslySetInnerHTML={{ __html: specialCharacterReplace(data.settings.edges[0].node.head[this.state.chartData]) }} />
            </div>
            <div className={styles.chartFilter}>
              <div className={styles.chartFilterInner}>
                <select name="type" value={this.state.type} id="makerShareType" onChange={this.handleChange}>
                  { data.settings.edges[0].node.select[this.state.chartData].map((item, id ) => {
                    return <option key={id} value={item.label}>{item.name}</option>
                  }) }
                </select>
              </div>
            </div>
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
            <div className={styles.chartNote}>
              <p className={styles.chartSource}>出所）
                { 
                  (data.settings.edges[0].node.source[this.state.chartData].between) ? 
                    <>
                      <a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink1} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText1}</a>
                      { data.settings.edges[0].node.source[this.state.chartData].between }
                      <a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink2} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText2}</a>
                      { data.settings.edges[0].node.source[this.state.chartData].after }
                    </> :
                    <a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink1} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText1}</a>
                }
              </p>
              <p className={styles.chartExplain}>{nl2br(data.settings.edges[0].node.source[this.state.chartData].explain)}</p>
              <p><a className={styles.chartDataDownload} href={`${this.filePath}${this.state.chartData}-${this.state.type}.csv`}>データダウンロード</a></p>
            </div>
          </>
        )}
      />
    )
  }
}
export default PieSelected
